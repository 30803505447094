




























































































































































































































import Vue from 'vue';
import { ELEMENTS_SHORTCUTSINFOSSET_QUERY } from '../queries/query';
import { ApolloError } from 'apollo-client';
import { Select, Option } from 'element-ui';
import { Table, TableColumn } from 'element-ui';
import draggable from 'vuedraggable';
import {
  ShortcutsInfosSet,
  ShortcutsInfosSetParam,
  ShortcutInfoParam,
  ShortcutVersion,
} from '../model/shortcut';
import Spinner from '@/components/Spinner.vue';
import { ClientPlatform } from '@frientrip/domain';
import { apolloClient } from '@/apolloClient';
import { ShortcutService } from '../service/ShortcutService';
import {
  imageUploadService,
  ContentTargetWithIdParam,
  ContentTarget,
} from '@/components/ImageUpload/imageUploadService';
import ShortcutInfoForm from '../components/ShortcutInfoForm.vue';

type ShortcutSetParam = Omit<ShortcutsInfosSetParam, 'items'> & {
  items: (ShortcutInfoParam & { isChanging: boolean; image: Image })[];
};

interface Image {
  contentId: string;
  url: string;
}

const shortcutService = new ShortcutService(apolloClient);

export default Vue.extend({
  name: 'ShortcutSetForm',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Spinner,
    draggable,
    ShortcutInfoForm,
  },
  data() {
    return {
      setId: this.$route.params.setId,
      loading: 0,
      showErrorMessageTime: 0,
      errorMessage: '',
      showSuccessMessageTime: 0,
      successMessage: '',
      versions: Object.keys(ShortcutVersion).map(version => ({
        text: version,
        value: ShortcutVersion[version as ShortcutVersion],
      })),
      isAnyChaning: false,
      shortcutSet: {
        id: '',
        title: '',
        version: ShortcutVersion.V2,
        items: [
          {
            id: '',
            name: '',
            contentId: '',
            image: { contentId: '', url: '' },
            seqNo: 1,
            isChanging: false,
            actionUris: [{ id: '', actionUri: '', clientPlatforms: [] }],
          },
        ],
      } as ShortcutSetParam,
    };
  },
  computed: {},
  methods: {
    shortcuttModalEvent(
      e: {
        willBeUpdate: boolean;
        name: string;
        image: Image;
        actionUris: {
          id: string;
          actionUri: string;
          clientPlatforms: ClientPlatform[];
        }[];
      },
      idx: number
    ) {
      if (e.willBeUpdate) {
        this.shortcutSet.items[idx].name = e.name;
        this.shortcutSet.items[idx].image = e.image;
        this.shortcutSet.items[idx].actionUris = e.actionUris;
      }
      this.shortcutSet.items[idx].isChanging = false; // modal down
    },
    actionUriToLink(actionUri: string): string {
      let result = actionUri;
      if (
        actionUri.search('frip://') === -1 &&
        actionUri.search('https') === -1 &&
        actionUri.search('http') === -1
      ) {
        result = `https://frip.co.kr/${actionUri}`;
      }
      return result;
    },
    platformToType(platform: ClientPlatform): string {
      let result = 'primary';
      if (platform === ClientPlatform.AndroidApp) {
        result = 'success';
      } else if (platform === ClientPlatform.iOSApp) {
        result = 'info';
      }
      return result;
    },
    changeEvent(): void {
      this.isAnyChaning = true;
    },
    ShortcutSetToParam(param: ShortcutSetParam): ShortcutsInfosSetParam {
      const { id, title, version, items } = param;
      const inputItems = items.map(
        ({ id, name, image, seqNo, actionUris }) => ({
          id: id || undefined,
          name: name,
          contentId: image.contentId,
          seqNo: seqNo,
          actionUris: actionUris.map(({ id, actionUri, clientPlatforms }) => ({
            id: id || undefined,
            actionUri: actionUri,
            clientPlatforms: clientPlatforms,
          })),
        })
      );
      return {
        id: id || undefined,
        title: title,
        version: version,
        items: inputItems,
      };
    },
    async deleteShortcutInfosSet(): Promise<void> {
      this.$modal.show(
        {
          title: '정말 삭제 하시겠습니까?',
          type: 'danger',
          showCancelButton: true,
        },
        async () => {
          try {
            await shortcutService.deleteShortcutInfosSet(
              this.shortcutSet.id || ''
            );
            this.$router.push({
              name: 'ShortcutSetList',
              params: {
                showSuccessMessageTime: '5',
                successMessage: '삭제되었습니다.',
              },
            });
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
          }
        }
      );
    },
    async saveShortcutInfosSet(): Promise<void> {
      this.$modal.show(
        {
          title: '저장 하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          const param = this.ShortcutSetToParam(this.shortcutSet);
          try {
            const savedResult = await shortcutService.saveShortcutInfosSet(
              param
            );
            const alreadyExistedContentIds = this.shortcutSet.items.map(
              ({ contentId }) => {
                return contentId;
              }
            );
            const newContentIdInfos = savedResult.items.filter(
              ({ content }) => !alreadyExistedContentIds.includes(content.id)
            );
            if (newContentIdInfos.length) {
              const contentTargetWithIdParam: ContentTargetWithIdParam[] =
                newContentIdInfos.map(({ id, content }) => ({
                  targetId: id,
                  contentId: content.id,
                  target: ContentTarget.SHORTCUT,
                }));
              await imageUploadService.finalizeContentsAndTarget(
                contentTargetWithIdParam
              );
            }
            if (this.isVisitCreatePage()) {
              this.setId = savedResult.id;
              this.showSuccessMessageTime = 10;
              this.successMessage = '등록 되었습니다.';
              this.$router.push({
                name: 'ShortcutSetUpdate',
                params: {
                  setId: savedResult.id,
                },
              });
            } else {
              this.$apollo.queries.shortcutSet.refetch();
              this.showSuccessMessage('수정 되었습니다.');
            }
            this.isAnyChaning = false;
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
          }
        }
      );
    },
    sortSeqNo(): void {
      this.shortcutSet.items.forEach((item, idx) => (item.seqNo = idx + 1));
    },
    deleteItemByIndex(idx: number): void {
      this.shortcutSet.items.splice(idx, 1);
      this.sortSeqNo();
    },
    addDefaultShortcutInfo(): void {
      this.shortcutSet.items.push({
        id: '',
        name: '',
        contentId: '',
        image: { contentId: '', url: '' },
        seqNo: this.shortcutSet.items.length + 1,
        isChanging: false,
        actionUris: [{ id: '', actionUri: '', clientPlatforms: [] }],
      });
    },
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number): void {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    showSuccessMessage(message: string): void {
      this.showSuccessMessageTime = 5;
      this.successMessage = message;
    },
    countDownSuccessChanged(showSuccessMessageTime: number) {
      this.showSuccessMessageTime = showSuccessMessageTime;
    },
    isVisitCreatePage(): boolean {
      return !this.$route.params.setId || this.$route.params.setId.length === 0;
    },
  },

  apollo: {
    shortcutSet: {
      query: ELEMENTS_SHORTCUTSINFOSSET_QUERY,
      variables(): { setId: string } {
        return {
          setId: this.$route.params.setId,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update: (data: {
        elements: { shortcutsInfosSet: ShortcutsInfosSet };
      }): ShortcutSetParam => {
        const { id, title, version } = data.elements.shortcutsInfosSet;
        const items = data.elements.shortcutsInfosSet.items.map(
          ({ id, name, seqNo, actionUris, content }) => ({
            id: id,
            name: name,
            seqNo,
            contentId: content.id,
            image: { contentId: content.id, url: content.uri },
            isChanging: false,
            actionUris: actionUris.map(
              ({ id, actionUri, clientPlatforms }) => ({
                id: id,
                actionUri: actionUri,
                clientPlatforms: clientPlatforms,
              })
            ),
          })
        );
        return { id: id, title: title, version: version, items: items };
      },
      skip(): boolean {
        return this.isVisitCreatePage();
      },
    },
  },
});
