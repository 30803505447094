var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"title":"홈 숏컷 관리"}},[(_vm.loading > 0)?_c('spinner'):_c('div',[_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center mb-0",staticStyle:{"text-align":"center"},attrs:{"header-row-class-name":"thead-light","data":_vm.shortcutSets}},[_c('el-table-column',{attrs:{"label":"아이디","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"타이틀","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.title)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"버전","prop":"version"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.version)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"상세 보기","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":row.id}},[_c('frip-button',{attrs:{"type":"secondary"}},[_vm._v("상세 보기")])],1)]}}])}),_c('el-table-column',{attrs:{"label":"활성화","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-button',{attrs:{"type":row.status === 'ACTIVE' ? 'danger' : 'secondary'},on:{"click":function($event){return _vm.activateShortcutInfosSet(row.id)}}},[_vm._v(_vm._s(row.status === 'ACTIVE' ? 'ON' : 'OFF'))])]}}])})],1),_c('b-alert',{attrs:{"show":_vm.showErrorMessageTime,"dismissible":"","variant":"frip-warning"},on:{"dismissed":function($event){_vm.showErrorMessageTime = 0},"dismiss-count-down":_vm.countDownChanged}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('b-alert',{attrs:{"show":_vm.showSuccessMessageTime,"dismissible":"","variant":"frip-success"},on:{"dismissed":function($event){_vm.showSuccessMessageTime = 0},"dismiss-count-down":_vm.countDownSuccessChanged}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")])],1),_c('b-card-footer',{staticClass:"text-right"},[_c('b-row',{staticClass:"float-right mx-3"},[_c('frip-button',{attrs:{"type":"primary","to":"./create","loading":_vm.loading > 0}},[_vm._v("숏컷 추가")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }