import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  ACTIVATE_SHORTCUTSINFOSSET_MUTATION,
  DELETE_SHORTCUTSINFOSSET_MUTATION,
  SAVE_SHORTCUTSINFOSSET_MUTATION,
} from '../queries/mutation';
import {
  ShortcutInfoActionUriParam,
  ShortcutInfoParam,
  ShortcutsInfosSet,
  ShortcutsInfosSetParam,
  ShortcutVersion,
} from '../model/shortcut';
import { platforms } from '@/common/platforms';
import { ClientPlatform } from '@frientrip/domain';
import _ from 'lodash';

export class ShortcutService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async saveShortcutInfosSet(
    param: ShortcutsInfosSetParam
  ): Promise<ShortcutsInfosSet> {
    this.validate(param);
    const result = await this.apollo.mutate<{
      saveShortcutsInfosSet: ShortcutsInfosSet;
    }>({
      mutation: SAVE_SHORTCUTSINFOSSET_MUTATION,
      variables: {
        param: param,
      },
    });
    if (!result || !result.data) {
      throw new Error(`숏컷 저장 결과값이 없습니다.`);
    }
    return result.data.saveShortcutsInfosSet;
  }

  async deleteShortcutInfosSet(setId: string): Promise<void> {
    await this.apollo.mutate({
      mutation: DELETE_SHORTCUTSINFOSSET_MUTATION,
      variables: {
        setId: setId,
      },
    });
  }

  async activateShortcutInfosSet(setId: string): Promise<void> {
    await this.apollo.mutate({
      mutation: ACTIVATE_SHORTCUTSINFOSSET_MUTATION,
      variables: {
        setId: setId,
      },
    });
  }

  validate(param: ShortcutsInfosSetParam): void {
    if (param.version === ShortcutVersion.V3 && param.items.length != 9) {
      throw new Error('V3 의 숏컷 갯수는 9개 여야 합니다.');
    }
    if (param.version === ShortcutVersion.V2 && param.items.length != 11) {
      throw new Error('V2 의 숏컷 갯수는 11개 여야 합니다.');
    }
    if (param.version === ShortcutVersion.V1 && param.items.length != 8) {
      throw new Error('V1 의 숏컷 갯수는 8개 여야 합니다.');
    }
    this.validateItems(param.items);
  }

  private validateItems(params: ShortcutInfoParam[]): void {
    if (params.length === 0) {
      throw new Error(`숏컷 정보가 존재하지 않습니다.`);
    }
    let nowSeqNo = 1;
    params.forEach(({ name, contentId, seqNo, actionUris }) => {
      // emoji length
      // https://stackoverflow.com/questions/40362669/count-character-length-of-emoji/46085147#46085147
      if (_.toArray(name).filter(it => it !== `\n`).length > 30) {
        throw new Error(`숏컷 이름의 최대길이 30 자를 넘었습니다. (${name})`);
      }
      if (nowSeqNo !== seqNo) {
        throw new Error(
          `seqNo 가 올바르지 않습니다. (expect:${nowSeqNo}, get:${seqNo})`
        );
      }
      nowSeqNo += 1;
      if (!contentId || contentId.length === 0) {
        throw new Error(`이미지가 존재하지 않습니다.`);
      }
      if (!name || name.length === 0) {
        throw new Error(`노출텍스트가 존재하지 않습니다.`);
      }
      this.validateActionUris(actionUris);
    });
  }

  private validateActionUris(params: ShortcutInfoActionUriParam[]): void {
    const inputedPlatform: ClientPlatform[] = [];
    params.forEach(({ actionUri, clientPlatforms }) => {
      if (!actionUri || actionUri.length === 0) {
        throw new Error(`URL정보가 존재하지 않습니다.`);
      }
      if (!clientPlatforms.length) {
        throw new Error(`플랫폼 정보가 존재하지 않습니다.`);
      }
      clientPlatforms.forEach(platform => {
        if (!platforms.includes(platform)) {
          throw new Error(
            `platform 정보가 올바르지 않습니다. (platform : ${platform})`
          );
        }
        if (inputedPlatform.includes(platform)) {
          throw new Error(
            `중복되는 platform 이 존재합니다. (platform : ${platform})`
          );
        }
        inputedPlatform.push(platform);
      });
    });
  }
}
