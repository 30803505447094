import { CommonState, ClientPlatform } from '@frientrip/domain';

export enum ShortcutVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
  V4 = 'V4',
  V5 = 'V5',
}

export interface ShortcutsInfosSet {
  id: string;
  title: string;
  items: ShortcutInfo[];
  version: ShortcutVersion;
  createdAt: string;
  updatedAt: string;
  status: CommonState;
}

export interface ShortcutInfo {
  id: string;
  name: string;
  seqNo: number;
  actionUris: ShortcutInfoActionUri[];
  content: { id: string; uri: string };
}

export interface ShortcutInfoActionUri {
  id: string;
  actionUri: string;
  clientPlatforms: ClientPlatform[];
}

export interface ShortcutsInfosSetParam {
  id?: string;
  title: string;
  items: ShortcutInfoParam[];
  version: ShortcutVersion;
}

export interface ShortcutInfoParam {
  id?: string;
  name: string;
  actionUris: ShortcutInfoActionUriParam[];
  contentId: string;
  seqNo: number;
}

export interface ShortcutInfoActionUriParam {
  id?: string;
  actionUri: string;
  clientPlatforms: ClientPlatform[];
}
