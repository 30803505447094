













































































import Vue, { VueConstructor } from 'vue';
import { ApolloError } from 'apollo-client';
import Spinner from '@/components/Spinner.vue';
import { ELEMENTS_SHORTCUTSINFOSSETS_QUERY } from '../queries/query';
import { Table, TableColumn } from 'element-ui';
import { ShortcutsInfosSet } from '../model/shortcut';
import { apolloClient } from '@/apolloClient';
import { ShortcutService } from '../service/ShortcutService';
import { CommonState } from '@frientrip/domain';

const shortcutService = new ShortcutService(apolloClient);

const statusToValue = (state: CommonState): number => {
  switch (state) {
    case CommonState.ACTIVE:
      return 1;
    case CommonState.INACTIVE:
      return 9;
    default:
      return 10;
  }
};

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'ShortcutSetList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
  },
  data() {
    return {
      shortcutSets: [] as ShortcutsInfosSet[],
      showErrorMessageTime: 0,
      errorMessage: '',
      showSuccessMessageTime:
        parseInt(this.$route.params.showSuccessMessageTime) || 0,
      successMessage: this.$route.params.successMessage || '',
      loading: 0,
    };
  },
  computed: {},
  methods: {
    async activateShortcutInfosSet(setId: string): Promise<void> {
      this.$modal.show(
        {
          title: '정말 활성화 하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          try {
            const shortcutSet = this.shortcutSets.find(set => set.id === setId);
            if (shortcutSet && shortcutSet.status === CommonState.ACTIVE) {
              throw new Error('이미 활성화 되어있는 셋입니다.');
            }
            await shortcutService.activateShortcutInfosSet(setId);
            this.$apollo.queries.shortcutSets.refetch();
            this.showSuccessMessage(
              `활성화 되었습니다. (${shortcutSet?.title})`
            );
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
          }
        }
      );
    },
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number) {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    showSuccessMessage(message: string): void {
      this.showSuccessMessageTime = 5;
      this.successMessage = message;
    },
    countDownSuccessChanged(showSuccessMessageTime: number) {
      this.showSuccessMessageTime = showSuccessMessageTime;
    },
  },
  apollo: {
    shortcutSets: {
      query: ELEMENTS_SHORTCUTSINFOSSETS_QUERY,
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update(data: {
        elements: { shortcutsInfosSets: ShortcutsInfosSet[] };
      }): ShortcutsInfosSet[] {
        const result = data.elements.shortcutsInfosSets.sort(
          (a, b) =>
            statusToValue(a.status) - statusToValue(b.status) ||
            parseInt(b.id) - parseInt(a.id)
        );
        return result;
      },
    },
  },
});
