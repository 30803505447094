import gql from 'graphql-tag';

export const ELEMENTS_SHORTCUTS_QUERY = gql`
  query ($count: Int, $version: ShortcutVersion) {
    elements {
      shortcuts(count: $count, version: $version) {
        title
        items {
          name
          actionUri
          content {
            id
            uri
          }
        }
      }
    }
  }
`;

export const ELEMENTS_SHORTCUTSINFOSSET_QUERY = gql`
  query ($setId: ID!) {
    elements {
      shortcutsInfosSet(setId: $setId) {
        id
        title
        items {
          id
          name
          seqNo
          actionUris {
            id
            actionUri
            clientPlatforms
          }
          content {
            id
            uri
          }
        }
        version
        createdAt
        updatedAt
        status
      }
    }
  }
`;

export const ELEMENTS_SHORTCUTSINFOSSETS_QUERY = gql`
  query ($page: Int, $pageSize: Int) {
    elements {
      shortcutsInfosSets(page: $page, pageSize: $pageSize) {
        id
        title
        items {
          id
          name
          seqNo
          actionUris {
            id
            actionUri
            clientPlatforms
          }
          content {
            id
            uri
          }
        }
        version
        createdAt
        updatedAt
        status
      }
    }
  }
`;
