

































import Vue from 'vue';
import { Select, Option } from 'element-ui';
import { Table, TableColumn } from 'element-ui';
import ImageUploadContainer from '@/components/ImageUpload/ImageUploadBoxWithImageObject.vue';
import ShortcutActionUris from './ShortcutActionUriForm.vue';

export default Vue.extend({
  name: 'ShortcutInfoForm',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    ImageUploadContainer,
    ShortcutActionUris,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      name: this.item.name,
      image: this.item.image,
      actionUris: JSON.parse(JSON.stringify(this.item.actionUris)), // array  deep copy
    };
  },
  computed: {},
  watch: {},
  methods: {
    updateInput(willBeUpdate: boolean) {
      this.$emit('change', {
        willBeUpdate: willBeUpdate,
        name: this.name,
        image: this.image,
        actionUris: this.actionUris,
      });
    },
  },
});
