import gql from 'graphql-tag';

export const ACTIVATE_SHORTCUTSINFOSSET_MUTATION = gql`
  mutation ($setId: ID!) {
    activeShortcutsInfosSet(setId: $setId) {
      id
      title
      items {
        id
        name
        seqNo
        actionUris {
          id
          actionUri
          clientPlatforms
        }
        content {
          id
        }
      }
      version
      createdAt
      updatedAt
      status
    }
  }
`;

export const DELETE_SHORTCUTSINFOSSET_MUTATION = gql`
  mutation ($setId: ID!) {
    deleteShortcutsInfosSet(setId: $setId)
  }
`;

export const SAVE_SHORTCUTSINFOSSET_MUTATION = gql`
  mutation ($param: ShortcutsInfosParam!) {
    saveShortcutsInfosSet(param: $param) {
      id
      title
      items {
        id
        name
        seqNo
        actionUris {
          id
          actionUri
          clientPlatforms
        }
        content {
          id
        }
      }
      version
      createdAt
      updatedAt
      status
    }
  }
`;
