
























































import { getPlatformSelectOptions } from '@/common/platforms';
import { Select, Option } from 'element-ui';
import Vue from 'vue';
export default Vue.extend({
  name: 'ShortcutActionUris',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      actionUris: this.value ? this.value : [],
      platformsOptions: getPlatformSelectOptions(),
    };
  },
  methods: {
    createActionUri(): void {
      this.actionUris.push({
        id: '',
        actionUri: '',
        clientPlatforms: [],
      });
      this.$emit('change');
    },
    deleteActionUri(idx: number): void {
      if (this.actionUris.length > 1) {
        this.actionUris.splice(idx, 1);
      }
      this.$emit('change');
    },
  },
});
